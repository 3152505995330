import { environment } from './../environments/environment';
import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { DataService } from './shared/modules/data-service/services/data.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  constructor(
    private dataService: DataService,
  ) {}

  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);

    if (! environment.production) {
      alert('Something went wrong, but don\'t worry!\n\nAn error report has been sent to the developers, you should also check your work.\n\nYou may want to reload the page.');
    }

    console.log('*** Error information');
    console.log(error);
    console.log('*** Error information');

    // If you want to capture user feedback, restore this code.
    //
    // const user = this.dataService.getCurrentUser();
    // Sentry.setUser({ name: user?.firstname, email: user?.email });
    // Sentry.showReportDialog({
    //   eventId,
    //   title: 'Something went wrong, but don\'t worry...',
    //   subtitle: '.',
    //   subtitle2: '.',
    // });
  }
}

