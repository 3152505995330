import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { LoopBackAuth } from '../sdk/services/core/auth.service';

@Injectable()

// Handle any 401 403 Unauthorized responses by redirecting to the login page,
// otherwise throw a new error with the message

export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private auth: LoopBackAuth
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                console.log('ErrorInterceptor: received an error');
                console.error(err);

                // For some reason, the server is returning status code 0 for some errors,
                // probably due to a bug in or server-side code (that is not setting the
                // status code). We need to catch these here so an alert is not shown to the
                // user.
                if ([0, 401, 403].includes(err.status) ) {
                    console.log(`Error status is ${err.status}, redirecting to auth/login`);

                    // VERY IMPORTANT! If you don't clear out the current auth, then the app
                    // may think you are still logged in and try to connect to the server,
                    // where it will get 401s and this will put you into an endless redirect
                    // loop.
                    this.auth.clear();

                    this.router.navigate(['auth/login']);

                    // Return an Observable so this error will be "resolved"
                    return new Observable<HttpEvent<any>>();
                }

                // ******************************************************
                // NOTE: All calls to the LoopBack backend via the SDK with errors
                // will be passed through the SDK ErrorHandler (see
                // src/app/shared/sdk/services/core/error.service.ts).
                // This handler then extracts the error message from the error,
                // so here we need to throw an actual error and not a string.
                // ******************************************************

                return throwError(err);
            })
        );
    }
}
