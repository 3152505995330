// main entry point
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://b97ff082f0810957f22fb6a2a8ef80e9@o4506026600431616.ingest.sentry.io/4506026602987520',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/fedsavvy\.io\/api/],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),

    // Sentry Replay will capture interactions so we can review them, but it
    // makes the initial page load very slow, so I have disabled it.
    // new Sentry.Replay(),
  ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  // replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));

