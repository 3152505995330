/* tslint:disable */
import {
  Profile,
  Address,
  InvitationCode,
  Attachment
} from '../index';

declare var Object: any;
export interface FSUserInterface {
  "id": string;
  "firstname"?: string;
  "lastname"?: string;
  "company"?: string;
  "invitationcode"?: string;
  "AccountActivated": boolean;
  "LockedOut": boolean;
  "LastLoginDate"?: Date;
  "LastLoginIp"?: string;
  "LastLoginGeoIp"?: any;
  "LastPasswordChangeDate"?: Date;
  "LastLockOutDate"?: Date;
  "Comment"?: string;
  "FailedPasswordAttemptCount"?: number;
  "FailedPasswordAtteptWindowStart"?: Date;
  "numLogins"?: number;
  "storageContainerId"?: string;
  "prospect"?: boolean;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "parentId"?: string;
  "createdByIp": string;
  "lastUpdatedByIp": string;
  "createdAt": Date;
  "updatedAt": Date;
  "password"?: string;
  parentUser?: FSUser;
  roles?: any[];
  profile?: Profile;
  accessTokens?: any[];
  billingAddress?: Address;
  invitationCodes?: InvitationCode[];
  attachments?: Attachment[];
}

export class FSUser implements FSUserInterface {
  "id": string;
  "firstname": string;
  "lastname": string;
  "company": string;
  "invitationcode": string;
  "AccountActivated": boolean;
  "LockedOut": boolean;
  "LastLoginDate": Date;
  "LastLoginIp": string;
  "LastLoginGeoIp": any;
  "LastPasswordChangeDate": Date;
  "LastLockOutDate": Date;
  "Comment": string;
  "FailedPasswordAttemptCount": number;
  "FailedPasswordAtteptWindowStart": Date;
  "numLogins": number;
  "storageContainerId": string;
  "prospect": boolean;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "parentId": string;
  "createdByIp": string;
  "lastUpdatedByIp": string;
  "createdAt": Date;
  "updatedAt": Date;
  "password": string;
  parentUser: FSUser;
  roles: any[];
  profile: Profile;
  accessTokens: any[];
  billingAddress: Address;
  invitationCodes: InvitationCode[];
  attachments: Attachment[];
  constructor(data?: FSUserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FSUser`.
   */
  public static getModelName() {
    return "FSUser";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FSUser for dynamic purposes.
  **/
  public static factory(data: FSUserInterface): FSUser{
    return new FSUser(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FSUser',
      plural: 'FSUsers',
      path: 'FSUsers',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "company": {
          name: 'company',
          type: 'string'
        },
        "invitationcode": {
          name: 'invitationcode',
          type: 'string',
          default: 'null'
        },
        "AccountActivated": {
          name: 'AccountActivated',
          type: 'boolean',
          default: true
        },
        "LockedOut": {
          name: 'LockedOut',
          type: 'boolean',
          default: false
        },
        "LastLoginDate": {
          name: 'LastLoginDate',
          type: 'Date',
          default: new Date(0)
        },
        "LastLoginIp": {
          name: 'LastLoginIp',
          type: 'string',
          default: 'null'
        },
        "LastLoginGeoIp": {
          name: 'LastLoginGeoIp',
          type: 'any',
          default: <any>null
        },
        "LastPasswordChangeDate": {
          name: 'LastPasswordChangeDate',
          type: 'Date',
          default: new Date(0)
        },
        "LastLockOutDate": {
          name: 'LastLockOutDate',
          type: 'Date',
          default: new Date(0)
        },
        "Comment": {
          name: 'Comment',
          type: 'string',
          default: 'null'
        },
        "FailedPasswordAttemptCount": {
          name: 'FailedPasswordAttemptCount',
          type: 'number',
          default: 0
        },
        "FailedPasswordAtteptWindowStart": {
          name: 'FailedPasswordAtteptWindowStart',
          type: 'Date',
          default: new Date(0)
        },
        "numLogins": {
          name: 'numLogins',
          type: 'number'
        },
        "storageContainerId": {
          name: 'storageContainerId',
          type: 'string',
          default: 'null'
        },
        "prospect": {
          name: 'prospect',
          type: 'boolean'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "parentId": {
          name: 'parentId',
          type: 'string'
        },
        "createdByIp": {
          name: 'createdByIp',
          type: 'string'
        },
        "lastUpdatedByIp": {
          name: 'lastUpdatedByIp',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        parentUser: {
          name: 'parentUser',
          type: 'FSUser',
          model: 'FSUser',
          relationType: 'belongsTo',
                  keyFrom: 'parentId',
          keyTo: 'id'
        },
        roles: {
          name: 'roles',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          modelThrough: 'RoleMapping',
          keyThrough: 'roleId',
          keyFrom: 'id',
          keyTo: 'principalId'
        },
        profile: {
          name: 'profile',
          type: 'Profile',
          model: 'Profile',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'FSUserId'
        },
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        billingAddress: {
          name: 'billingAddress',
          type: 'Address',
          model: 'Address',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'FSUserId'
        },
        invitationCodes: {
          name: 'invitationCodes',
          type: 'InvitationCode[]',
          model: 'InvitationCode',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'FSUserId'
        },
        attachments: {
          name: 'attachments',
          type: 'Attachment[]',
          model: 'Attachment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'attachedToId'
        },
      }
    }
  }
}
