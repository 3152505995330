import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';

import { GlobalPropertiesService } from './shared/modules/global-properties/services/global-properties.service';
import { LazyloaderService } from './shared/services/lazyloader.service';
import { LoopBackAuth } from './shared/sdk/services/core/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public app;

  constructor(
    public globalProps: GlobalPropertiesService,
    protected auth: LoopBackAuth,
    private router: Router
  ) {
    this.app = this.globalProps.appGlobal;
  }

  ngOnInit(
  ) {
    console.log('FS Frontend Loaded... Ready to rock. v' + this.globalProps.appGlobal.version);

    // prevent user from refreshing page by pulling down, normally i don't like this, but it can mess things up if done by accident
    document.addEventListener('touchmove', function (event: any) {
      event = event.originalEvent || event;
      if (event.scale && event.scale !== 1) {
        event.preventDefault();
      }
    }, false);

    window.performance.mark('fs_loaded');

    // Check if token has expired and redirect
    const logoutCheckInterval = 30 * 1000;  // 30s

    interval(logoutCheckInterval).subscribe(x => {
      const token = this.auth.getToken();
      if ( token && token.created ) {  // Skip if there is no token
        const expires = ( Date.parse(token.created) + ( token.ttl * 1000) - Date.now() ) / 1000;
        console.log(`** Login expires in ${ Math.floor(expires) } seconds`) ;

        if ( expires < 0 ) {
          console.log ('Login has expired');
          this.auth.clear();
          this.router.navigate(['auth/login']);
        }
      }
    });

  }

}


