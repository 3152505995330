import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { SharedModule } from './shared/modules/shared.module/shared.module'; // should not need this now
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';

// loopback
// import { SDKBrowserModule } from './shared/sdk/index'; // DO NOT USE THIS!
import { GlobalPropertiesModule } from './shared/modules/global-properties/global-properties.module';
import { ToastContainerDirective, ToastrModule } from 'ngx-toastr';
import { ApiAuthLoaderModule } from './shared/modules/api-auth-loader/api-auth-loader.module';
import { DataServiceModule } from './shared/modules/data-service/data-service.module';
import { ReactiveFormsModule, FormsModule, ValidationErrors } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';
import { FieldQuillType } from './core.module/components/quill-type/quill.type';

import { CustomErrorHandler } from './custom-error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './shared/services/error.interceptor';
import { IdleTimeInterceptor } from './shared/services/idle-time.interceptor';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

@NgModule({
  declarations: [
    AppComponent,
    FieldQuillType
  ],
  imports: [
    GlobalPropertiesModule.forRoot(),
    ApiAuthLoaderModule.forRoot(), // just loads the auth and base storage providers. Other api models need provided in the module where they are used
    DataServiceModule.forRoot(),
    BrowserAnimationsModule, // used by primeNG
    // SDKBrowserModule.forRoot(), // loopback sdk (don't use this, because it imports EVERYTHING. Instead use providers for what you need in modules)
    BrowserModule,
    AppRoutingModule, // main routes defined in here
    ToastrModule.forRoot(), // used on login page
    ToastContainerDirective,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline'],        // toggled buttons
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'color': [] }],          // dropdown with defaults from theme
       //   [{ 'font': [] }],
          [{ 'align': [] }],
          [ 'link' ],
          ['clean']                                         // remove formatting button
        ]
      }
    }),
    FormlyModule.forRoot({
      validators: [{ name: 'atLeastOneChecked', validation: atLeastOneCheckedValidator}],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'atLeastOneChecked', message: 'At least one checkbox must be selected' },
      ],
      types: [
        {
          name: 'quill',
          component: FieldQuillType,
          wrappers: ['form-field'],
        },
      ],
    }),
    FormlyBootstrapModule,
    MessageModule,
    MessagesModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },  // Handle 401 403 errors
    { provide: HTTP_INTERCEPTORS, useClass: IdleTimeInterceptor, multi: true },  // Keep track of last API call
    { provide: Sentry.TraceService, deps: [Router], },  // Sentry
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true, },  // Sentry
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// Return true if validation passes
export function atLeastOneCheckedValidator(formGroup: any, fieldGroup: any): ValidationErrors {

  const atLeastOneIsChecked = !! fieldGroup.fieldGroup.map( f => f.key )  // Get all keys in this FormGroup
    .map( k => formGroup.controls[k] )               // Get the FormGroup objects
    .map( c => c.value )                             // Get the values
    .find( k => k );                                 // See if any are true, need !! in case find returns null


  return atLeastOneIsChecked ? null : { atLeastOneChecked: true };
}



