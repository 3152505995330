import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { LoopBackAuth } from '../sdk/services/core/auth.service';

@Injectable()

// Keep track of the last API request and update the token created date so we can use it for
// the idle-time check.

export class IdleTimeInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private auth: LoopBackAuth,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap( res => {
                const token = this.auth.getToken();

                // Only update once a second, so multiple requests don't create multiple updates
                if ( ( Date.now() - Date.parse(token.created) ) > 1000 ) {
                    token.created = new Date();
                    this.auth.save();
                }
            })
        );
    }
}
