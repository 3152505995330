import { RealTime } from './../../sdk/services/core/real.time';
import { SocketBrowser } from './../../sdk/sockets/socket.browser';
import { SocketDriver } from './../../sdk/sockets/socket.driver';
import { SDKStorage } from 'app/shared/sdk/storage/storage.swaps';
import { StorageBrowser } from './../../sdk/storage/storage.browser';
import { InternalStorage } from './../../sdk/storage/storage.swaps';
import { LoopBackAuth } from './../../sdk/services/core/auth.service';
import { SocketConnection } from 'app/shared/sdk/sockets/socket.connections';
import { LoggerService } from 'app/shared/sdk/services/custom/logger.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandler } from 'app/shared/sdk/services/core/error.service';
import { CookieBrowser } from 'app/shared/sdk/storage/cookie.browser';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    ErrorHandler,
    SocketConnection
  ]
})
export class ApiAuthLoaderModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders<ApiAuthLoaderModule> {
    return {
      ngModule: ApiAuthLoaderModule,
      providers: [
        LoopBackAuth,
        LoggerService,
        RealTime,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser }
      ]
    };
  }


}
