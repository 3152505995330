import { LoopBackAuth } from './../../../sdk/services/core/auth.service';
import { LoggerService } from './../../../sdk/services/custom/logger.service';
import { LoopBackConfig } from '../../../sdk/lb.config';
import { RealTime } from '../../../sdk/services/core/real.time';
import { Injectable, EventEmitter } from '@angular/core';
import { FSUserApi } from '../../../sdk/services/custom/FSUser';
import { FSUser } from '../../../sdk/models/FSUser';
import { BehaviorSubject } from 'rxjs';
import { DataURLS } from '../../../base.url';
import { take, takeUntil } from 'rxjs/operators';

import { Company } from './../../../sdk/models/Company';
import { ContractVehicleName } from './../../../sdk/models/ContractVehicleName';
import { ContractType } from './../../../sdk/models/ContractType';
import { Location } from './../../../sdk/models/Location';


@Injectable()
export class DataService {
  public realtimeConnectionChange$: BehaviorSubject<{
    status: string,
    connected: boolean,
    connecting: boolean,
    authenticated: boolean
  }> = new BehaviorSubject({
    status: 'disconnected',
    connected: false,
    connecting: false,
    authenticated: false
  }); // start with not connected status
  public currentUserChange$: BehaviorSubject<FSUser> = new BehaviorSubject(null);
  public currentUser: FSUser = null; // dummy starting user info so binding to values in components will work
  public impersonationUser: FSUser;
  public isImpersonatingUser: Boolean;
  public userRole: string;
  public effectiveRole: string;
  public childRole: string;
  public effectiveChildRole: string;
  public realtimeStarted = false;

  public isLoadingUser = false;
  public userLoadComplete = new EventEmitter<FSUser>();

  // cache
  public companyCache: Company[];
  public govDepartmentCache: Object;
  public contractVehicleNameCache: ContractVehicleName[];
  public contractTypeCache: ContractType[];
  public locationCache: Location[];
  public usersCache: FSUser[];

  // realtime refs
  private userFilter = {
    include: ['roles', 'profile', {
      relation: 'parentUser',
      scope: {
        fields: ['id', 'profile', 'roles'],
        include: [{
          relation: 'roles'
        }, 'profile']
      }
    }
    ]
  };


  // permission bindables
  // which pages is the current user allowed to view
  public pageAuth = {
    adminmanagement: false,
    customers: false,
    systemmanagement: false, // alias for system
    companies: false,
    editdata: false,
    search: false,
    ingestcontrol: false,
    ingesthistory: false,
    system: false,
    citool: false,
    tagsedit: false,
    revisionhistory: false,
    opportunities: false,
    scoretypes: false,
    scraper: false
  };

  // which models are visible on company page and edit data
  public modelAuth = {
    Acquisition: false,
    BusinessUnit: false,
    Certification: false,
    Company: false,
    CorporateLeadership: false,
    Facility: false,
    MarketingStory: false,
    Opportunity: false,
    Partnership: false,
    Patent: false,
    Personnel: false,
    PressRelease: false,
    Technology: false,
    Trademark: false,
    Tweet: false,
    Process: false
  };

  public specialAuth = {
    NoExport: false,
    NoCreateTag: false
  };

  constructor(
    public logger: LoggerService,
    public fsUserAPi: FSUserApi,
    public realTime: RealTime,
    public loopbackAuth: LoopBackAuth
  ) {

    LoopBackConfig.setApiVersion(DataURLS.API_VERSION);
    LoopBackConfig.setBaseURL(DataURLS.BASE_URL);

    // start realtime connection
    // this.createRealtimeRefs();

    // set pageview permission bindables
    this.currentUserChange$.subscribe(user => {
      // set page permissions when new user is loaded
      if (user) {
        for (const k of Object.keys(this.pageAuth)) {
          this.pageAuth[k] = this.permissionsCheckPath(user, `/main/${k}`); // set permission
        }

        // model view props
        for (const k of Object.keys(this.modelAuth)) {
          this.modelAuth[k] = this.permissionsCheckModel(user, k); // set permission
        }

        this.specialAuth.NoExport = this.permissionsCheckSpecial(user, 'NoExport'); // data export allowed
        this.specialAuth.NoCreateTag = this.permissionsCheckSpecial(user, 'NoCreateTag'); // data export allowed

      }
    });

  }
  connectRealtime() {
    if (!this.realtimeStarted) {
      this.createRealtimeRefs();
      this.realtimeStarted = true;
    }
  }

  disconnectRealtime() {
    this.realtimeStarted = false;

    if (this.realTime.connection.isConnected()) {
      this.realTime.connection.disconnect();

    }
    this.realtimeConnectionChange$.next({ status: 'disconnected', connected: false, connecting: false, authenticated: false });
  }

  async createRealtimeRefs() {
    this.realtimeConnectionChange$.next({ status: 'connecting', connected: false, connecting: true, authenticated: false });

    const that = this;


    this.realTime.onReady().pipe(take(1)).subscribe((status: string) => {
      // crate refs

      // FOR TESTING: should be able to query data at this point
      //  const ltoData = await this.ltoApi.findById<LimitedTimeOffer>(this.elementConfig.ltoId, { include: 'offerCodes' }).toPromise();
      // if (!this.fsUserAPi.isAuthenticated()) {
      //   this.logger.info(`Realtime authenticated... with userid: ${this.currentUser.id}`);
      // } else {
      //   this.logger.info(`Realtime started unauthenticated...`);
      // }

      if (this.realTime.connection.authenticated) {
        that.realtimeConnectionChange$.next({ status: 'connected', connected: true, connecting: false, authenticated: false });
      } else {
        this.logger.info(`Realtime has started unauthenticated...`);
      }

      this.realTime.onAuthenticated().pipe(take(1)).subscribe((msg: any) => {
        let user;
        try {
          user = this.loopbackAuth.getCurrentUserData();
        } catch (error) {

        }

        this.logger.info(`Realtime authenticated... with userid: ${user && user.id ? user.id : this.loopbackAuth.getCurrentUserId()}`);
        that.realtimeConnectionChange$.next({ status: 'connected', connected: true, connecting: false, authenticated: true });
      });

      this.realTime.onUnAuthorized().pipe(take(1)).subscribe(msg => {
        this.logger.info(`Realtime not authorized to perform action... ${msg}`);
        that.realtimeConnectionChange$.next({ status: 'connected', connected: false, connecting: false, authenticated: false });
      });

      this.realTime.onDisconnect().pipe(take(1)).subscribe(msg => {
        that.realtimeConnectionChange$.next({ status: 'disconnected', connected: false, connecting: true, authenticated: true });
        this.logger.info('Realtime disconnected from server!');
        this.logger.info('Trying to reconnnect now...');
        this.createRealtimeRefs();
      });


    });
  }

  async impersonateUser(id: string) {
    this.impersonationUser = <FSUser>await this.fsUserAPi.findById(id, this.userFilter).toPromise(); // set temp user
    await this.setCurrentUser();  // sets the current user
    this.isImpersonatingUser = true;
  }

  async clearImpersonation() {
    this.impersonationUser = null;
    this.isImpersonatingUser = false;

    // set user again
    await this.setCurrentUser();  // sets the current user
  }

  getCurrentUser(): FSUser {
    // return impsersonated user, or return actual user
    // if (!this.currentUser.id) {
    //   await this.setCurrentUser(); // set if empty
    // }

    return this.currentUser;
  }

  async setCurrentUser(): Promise<FSUser> {
    let tempUser: FSUser;
    let changed: boolean;
    this.isLoadingUser = true;

    try {
      if (this.impersonationUser) {
        tempUser = this.impersonationUser;
      } else {
        if (this.fsUserAPi.getCurrentId()) {
          // get current user
          tempUser = await this.fsUserAPi.getCurrent(this.userFilter).toPromise();
        } else {
          // user is logged out.. clear everything
          tempUser = new FSUser();
        }

      }

      if (!this.currentUser || tempUser.id !== this.currentUser.id) {
        changed = true;
      }

      this.currentUser = tempUser;

      this.userRole = await this.getCurrentRole();


      if (this.userRole === 'SubUser') {
        this.effectiveRole = this.currentUser.parentUser && this.currentUser.parentUser.roles && this.currentUser.parentUser.roles.length ? this.currentUser.parentUser.roles[0].name : 'unknown';
      } else {
        this.effectiveRole = this.userRole;
      }

      this.effectiveChildRole = await this.getEffectiveChildRole();

      this.childRole = await this.getChildRole();

      if (changed) {
        this.isLoadingUser = false;
        this.currentUserChange$.next(this.currentUser);
      }

    } catch (error) {
      this.logger.error('could not set current user!', error);
      throw error; // rethrow
    } finally {
      this.isLoadingUser = false;
      this.userLoadComplete.emit(this.currentUser);
      return this.currentUser;
    }
  }

  getCalculatedUserId(user: FSUser = null): string {
    // default to current user
    if (!user) {
      if (this.impersonationUser) {
        user = this.impersonationUser;
      } else {
        user = this.currentUser;
      }
    }

    const role = (<any>user).primaryrole;

    if (role === 'SubUser') {
      return user.parentUser.id;
    } else {
      return user.id;
    }

  }

  async getAssoiatedDistributorId(): Promise<string> {
    const rolename = await this.getCurrentRole();
    const curUser = this.getCurrentUser();

    if (rolename === 'Distributor') {
      return curUser.id;
    } else {
      return curUser.parentId;
    }
  }

  async getHighestOwnerId(): Promise<string> {
    const rolename = await this.getCurrentRole();
    const curUser = this.getCurrentUser();

    // return self
    if (rolename === 'SiteAdmin') {
      return curUser.id;
    }

    // return self
    if (rolename === 'Distributor') {
      return curUser.id;
    }

    // return Distribuor
    if (['User', 'SubUser', 'Customer'].indexOf(rolename) > -1) {
      return curUser.parentId;
    }
  }


  async getCurrentRole(): Promise<string> {
    let role = '';

    if (this.currentUser && this.currentUser.id) {
      role = this.currentUser && this.currentUser.roles && this.currentUser.roles.length ? this.currentUser.roles[0].name : 'unknown';
    }

    return role;
  }

  // return a predefined child role based on the current user role
  async getChildRole(): Promise<string> {
    const userRole = await this.getCurrentRole();
    return this.calcChildRole(userRole);
  }

  calcChildRole(parentRole: string) {
    let childRole = '';

    if (parentRole === 'SiteAdmin') { childRole = 'Distributor'; }
    if (parentRole === 'Distributor') { childRole = 'Customer'; }
    if (parentRole === 'Customer') { childRole = 'User'; }
    if (parentRole === 'SubUser') { childRole = ''; }
    if (parentRole === 'Guest') { childRole = ''; }

    return childRole;
  }

  getEffectiveChildRole(user: FSUser = null): string {
    if (!user) {
      if (this.impersonationUser) {
        user = this.impersonationUser;
      } else {
        user = this.currentUser;
      }
    }

    const userRole = this.getEffectiveRole(user);
    let childRole = '';

    if (userRole === 'SiteAdmin') { childRole = 'Distributor'; }
    if (userRole === 'Distributor') { childRole = 'Customer'; }
    if (userRole === 'Customer') { childRole = 'SubUser'; }
    if (userRole === 'SubUser') { childRole = ''; }
    if (userRole === 'Guest') { childRole = ''; }

    return childRole;
  }

  getEffectiveRole(user: FSUser): string {
    if (!user) { return null; }

    const userRole = user && user.roles && user.roles.length ? user.roles[0].name : null;
    let effectiveRole = '';

    // use parent role if subuser
    if (userRole === 'SubUser') {
      effectiveRole = this.getParentRole(user);
    } else {
      effectiveRole = userRole;
    }

    return effectiveRole;
  }

  getParentRole(user: FSUser): string {
    return user && user.parentUser && user.parentUser.roles && user.parentUser.roles.length ? user.parentUser.roles[0].name : null;
  }

  getUserPermissions(user: FSUser) {
    const permissions = user?.profile?.permissions;

    if (permissions) {
      return permissions;

    } else if (user?.profile && !permissions) {
      //  maintain backwards compat
      return null;
    }

    return null;
  }

  permissionsCheckPath(user: FSUser, path): boolean {
    const p = this.getUserPermissions(user);

    if (p) {
      // check in hidden paths
      if (p.hiddenPaths.length && p.hiddenPaths.indexOf(path) > -1) {
        return false;
      }

      // if no hidden paths, check if in visible paths or 'any'
      if (p.visiablePaths.indexOf('any') > -1 || p.visiablePaths.indexOf(path) > -1) {
        return true;
      }

      // default to true if hidden and visiable are empty for some reason
      if (!p.visiablePaths.length) {
        return true;
      }


    } else {
      // default to allow for backwards compat
      return true;
    }
  }

  permissionsCheckModel(user: FSUser, model: string): boolean {
    const p = this.getUserPermissions(user);

    if (p) {
      // check in hidden models
      if (p.hiddenModels.length && p.hiddenModels.indexOf(model) > -1) {
        return false;
      }

      // if no hidden models, check if in visible models or 'any'
      if (p.visibleModels.indexOf('any') > -1 || p.visibleModels.indexOf(model) > -1) {
        return true;
      }

      // default to true if hidden and visiable are empty for some reason
      if (!p.visiableModels.length) {
        return true;
      }
    } else {
      // default to allow for backwards compat
      return true;
    }
  }

  permissionsCheckSpecial(user: FSUser, permission: string): boolean {
    const p = this.getUserPermissions(user);

    if (p) {
      // check in special permissions
      if (p.special.length && p.special.indexOf(permission) > -1) {
        return true;
      }

    }
  }

}
