/* tslint:disable */
import { FireLoopRef } from './FireLoopRef';

export class FireLoop {

  private references: any = {};

  constructor(private socket: any) {}

  public ref<T>(model: any): FireLoopRef<T> {
    let name: string = model.getModelName();
    this.references[name] = new FireLoopRef<T>(model, this.socket);
    return this.references[name];
  }
}
